import React, { useState } from "react"
import NeedHelpIcon from "../images/need_help.svg"
import {
  faCommentDots,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "./anmeldung/components/Button"

function HelpArea(props: { withFounderText?: boolean }) {
  const [showChatbutton, setShowChatbutton] = useState(false)

  React.useEffect(() => {
    // setTimeout(() => setShowChatbutton(true), 499)
    const interval = setInterval(() => {
      if (window?.HubSpotConversations && window.HubSpotConversations?.widget)
        setShowChatbutton(window.HubSpotConversations.widget.status().loaded)
    }, 500)
    if (showChatbutton) {
      clearInterval(interval)
    }
  }, [showChatbutton])

  return (
    <section className="container bg-white py-8 px-6 mx-auto max-w-5xl">
      <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
        Hast du noch eine Frage?
      </h2>
      <div className="w-full mb-4">
        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
      </div>
      <div className="flex flex-wrap flex-col-reverse sm:flex-row">
        <div className="w-full sm:w-7/12 sm:pr-12 flex-start">
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <p className="text-gray-600 mb-8 text-lg text-justify">
              Wir würden uns sehr darüber freuen Dir Deine Fragen persönlich
              beantworten zu können!
            </p>
            {props.withFounderText && (
              <p className="text-gray-600 mb-8 text-lg text-justify">
                Auch wenn unser Team auf mittlerweile über 10 Kollegen gewachsen
                ist, beantworten wir, Yannic & Lenz, auch noch einige Anfragen
                persönlich. Eure Fragen zu beantworten ist uns eine große Hilfe,
                um unser Angebot in Zukunft noch besser und verständlicher zu
                machen.
              </p>
            )}
          </div>
          {/* {showChatbutton ? (
            <Button
              onClick={() => window.HubSpotConversations.widget.open()}
              classes="w-full mx-auto  md:w-auto md:mr-2"
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                size="1x"
                className="mr-2"
              />
              <>Chat starten</>
            </Button>
          ) : (
            <a href="tel:+4940524796490">
              <Button classes="w-full mx-auto  md:w-auto md:mr-2">
                <FontAwesomeIcon icon={faPhone} size="1x" className="mr-2" />
                <>Anrufen (040 524 796 490)</>
              </Button>
            </a>
          )} */}
          <a href="mailto:support@smartificate.de">
            <Button classes="w-full mx-auto md:w-auto md:mr-2">
              <FontAwesomeIcon icon={faEnvelope} size="1x" className="mr-2" />
              <>E-Mail</>
            </Button>
          </a>
        </div>
        <div className="w-full sm:w-5/12 mb-6 sm:mb-0">
          <NeedHelpIcon className="mx-auto w-full h-auto" />
        </div>
      </div>
    </section>
  )
}

export default HelpArea

import React from "react"
import Footer from "./Footer"
import ExplainIcon from "../images/FrontPage/howThisWorks.svg"
import Referral from "../images/FrontPage/referral.svg"
import HelpArea from "./HelpArea"
import { PressSlider } from "./PressSlider"
import YouTube from "@u-wave/react-youtube"
import { Branding } from "../state/anmeldung/anmeldungSlice"
function MainContent(props: {
  goToFlow: () => void
  gesetzesgrundlageOpend: boolean
  whiteLabel?: boolean
  partnerName?: Branding
}) {
  return (
    <>
      {!props.whiteLabel && <PressSlider />}
      {/* {!leadChannel.displayFrontpage && !props.whiteLabel && (
        <div className="w-11/12 flex flex-col md:flex-row max-w-4xl mt-6 m-auto text-xl p-6 my-4 rounded-md shadow-md ">
          <IconHighFive className="m-auto w-5/6 h-auto md:mr-6" />
          <div>
            <h2 className="w-full my-2 text-2xl font-black leading-tight text-gray-800">
              Neu: Auszahlung auf 250€ jährlich erhöht! 🎉
            </h2>
            Das Umweltbundesamt hat kürzlich die pauschale Menge der
            CO2-Einsparung Deines Elektroautos noch einmal erhöht. Deshalb
            erhältst Du von uns jetzt sogar 250€ pro Jahr ausgezahlt!
          </div>
        </div>
      )} */}
      <section
        id="mehr-erfahren"
        className="container bg-white py-8 px-4 md:px-6 max-w-5xl mx-auto"
      >
        <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
          Warum bekomme ich Geld für meine THG-Quote?
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
        </div>
        <div className="w-full max-w-4xl mx-auto mb-8">
          <div className="player-wrapper">
            <YouTube
              video="LvW3ZlJsOU8"
              className="react-player"
              showCaptions={true}
              width="100%"
              height="100%"
              showRelatedVideos={false}
              annotations={false}
            />
          </div>
        </div>
        <div className="flex flex-wrap flex-col-reverse sm:flex-row">
          <div className="w-full sm:w-1/2 p-3 flex-start">
            <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
              Hintergrund
            </h3>
            <p className="text-gray-600 mb-8 text-lg">
              Als Fahrer eines E-Autos hilfst Du aktiv mit, klimaschädliche
              Treibhausgasemissionen zu reduzieren. Dieses Engagement erkennt
              der Gesetzgeber ab 2022 an. Im Rahmen des Förderinstruments
              Treibhausgasminderungsquote (kurz THG-Quote), kann man Deine
              CO2-Einsparungen zertifizieren und an quotenpflichtige Unternehmen
              verkaufen. Somit hast Du die Möglichkeit mit uns Geld von
              Ölkonzernen in die Elektromobilität umzuverteilen.
            </p>
            <p className="text-gray-600 mb-8 text-lg">
              Dieser Prozess ist bislang durch den großen Verwaltungsaufwand nur
              für Flottenbetreiber mit vielen Fahrzeugen interessant. Wir von
              smartificate setzen uns dafür ein, dass auch private
              Fahrzeughalter von dieser Regelung profitieren und ihre THG-Quote
              verkaufen können. Wir übernehmen die komplette Abwicklung für
              bereits über 50.000 Kunden und gerne auch für Dich. Du brauchst
              Dich lediglich auf Deine Auszahlung zu freuen.
            </p>
          </div>
          <div className="w-5/6 sm:w-1/2 p-3 ">
            <ExplainIcon className="m-auto w-5/6 h-auto" />
          </div>
        </div>
      </section>
      {!props.whiteLabel && (
        <section
          id="Freunde werben"
          className="container bg-white py-8 px-4 md:px-6 max-w-5xl mx-auto"
        >
          <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
            Belohne deine Freunde gleich mit!
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-3 flex-start">
              <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                🌟 20€ Freundschaftswerbung 🌟
              </h3>
              <p className="text-gray-600 mb-8 text-lg">
                Nach Deiner Anmeldung bei uns erhältst Du von uns einen Link,
                den Du mit Deinen Freunden teilen kannst.
              </p>
              <p className="text-gray-600 mb-8 text-lg">
                Für jede Anmeldung über diesen Link{" "}
                <b>
                  erhaltet Ihr beide jeweils einen 10€ Bonus zusätzlich zu eurer
                  THG-Prämie!
                </b>
              </p>
            </div>
            <div className="w-5/6 sm:w-1/2 p-3 ">
              <Referral className="m-auto w-5/6 h-auto" />
            </div>
          </div>
        </section>
      )}
      
      <HelpArea />

      <Footer jetztBerechnen={props.goToFlow} partnerName={props.partnerName} />
    </>
  )
}

export default MainContent

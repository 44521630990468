import { navigate, PageProps } from "gatsby"
import { Router } from "@reach/router"
import BasicPageGreen from "../../components/PageWrapper/BasicPageGreen"
import React from "react"
import AnmeldungRouter, {
  anmeldungRoutesAbs,
} from "../../components/anmeldung/anmeldungRouter"
import MainContent from "../../components/MainContent"

const Anmeldung = (props: PageProps) => {
  return (
    <>
      <BasicPageGreen
        title="Anmeldung"
        hideTitle={true}
        noindex={true}
        location={props.location}
        belowGreenPart={
          <MainContent
            goToFlow={() => navigate(`${anmeldungRoutesAbs.start}`)}
            gesetzesgrundlageOpend={false}
          />
        }
      >
        <Router basepath="/anmeldung">
          <AnmeldungRouter path="/" default />
        </Router>
      </BasicPageGreen>
    </>
  )
}
export default Anmeldung

import React from "react"
import { useState } from "react"
import Nav from "../Nav/Nav"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Footer from "../Footer"
import { navigate } from "gatsby" //import navigate from gatsby
import { Helmet } from "react-helmet"
import { useAppDispatch } from "../../state/createStore"
import { extendUtmDictString } from "../../helpers/Tracking/tracking"
import { anmeldungRoutesAbs } from "../anmeldung/anmeldungRouter"

function BasicPageGreen(props: {
  children?: JSX.Element
  title: string
  hideTitle?: boolean
  location: { search: string }
  noindex?: boolean
  belowGreenPart?: JSX.Element
}) {
  const [scrolled, setScrolled] = useState(false)
  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) setScrolled(true)
    else setScrolled(false)
  })

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const combinedUtmRaw = extendUtmDictString(
      localStorage.getItem("utm"),
      props.location.search
    )
    if (combinedUtmRaw) {
      localStorage.setItem("utm", combinedUtmRaw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: "de-de" }}>
        <meta charSet="utf-8" />
        <title>{props.title} - smartificate</title>
        <meta name="description" content="" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
        {props.noindex && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className={`min-h-screen pt-14 pb-12 gradient`}>
        {/*Nav*/}
        <Nav
          scrolled={scrolled}
          logoClick={() => navigate("/")}
          calcNow={() => navigate(anmeldungRoutesAbs.start)}
        />
        {/*Hero*/}
        <div className="mx-auto flex flex-col md:flex-row w-full justify-center items-center items-start text-left mt-8 mb-10 md:mb-4">
          {/* <IconCarWide className="block max-h-24 w-64 " /> */}
          {!props.hideTitle && (
            <h1 className="text-3xl mt-0 font-bold leading-tight text-white ">
              {props.title}
            </h1>
          )}
        </div>
        <div className="mx-auto">{props.children}</div>
      </div>
      {props.belowGreenPart ? props.belowGreenPart : <Footer />}
    </>
  )
}
export default BasicPageGreen

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

import "./pressSlider.css"

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper"

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay])

export const PressSlider = (props: {}) => {
  return (
    <section className="bg-white pt-8 pb-0 px-6 mx-auto">
      <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
        Bekannt aus
      </h2>
      <div className="w-full mb-4">
        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
      </div>
      <div className="w-full">
        <Swiper
          // spaceBetween={10}
          breakpoints={{
            // when window width is >= 768px
            768: {
              slidesPerView: 5,
              slidesPerGroup: 1,
            },
          }}
          watchSlidesProgress={true}
          slidesPerView={2}
          slidesPerGroup={2}
          // spaceBetween={25}
          // slidesPerGroup={1}
          loop={true}
          //   loopFillGroupWithBlank={true}
          //   pagination={{
          //     clickable: true,
          //   }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          navigation={false}
          preventInteractionOnTransition={true}
          preventClicksPropagation={true}
          preventClicks={true}
          onSwiper={(swiper) =>
            setTimeout(() => {
              swiper.loopDestroy()
              swiper.loopCreate()
              console.log("reborn!")
            })
          }
        >
          <SwiperSlide id="slide1">
            <a
              href="https://www.welt.de/motor/news/article234190260/Ein-sauberer-Deal-Emissionshandel-fuer-E-Auto-Fahrer.html"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/WeltLogo.png"}
                  className="object-cover w-full h-full"
                  alt="Welt"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide2">
            <a
              href="https://www.handelsblatt.com/mobilitaet/elektromobilitaet/emissionshandel-so-koennen-elektroautofahrer-mit-ihrem-auto-geld-verdienen/27674940.html"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/Handelsblatt.png"}
                  className="object-cover w-full h-full"
                  alt="Handelsblatt"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide3">
            <a
              href="https://www.abendblatt.de/wirtschaft/article233921805/e-auto-zusatzverdienst-emissionen-praemie.html"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/ha.jpeg"}
                  className="object-cover w-full h-full"
                  alt="Hamburger Abendblatt"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide4">
            <a
              href="https://www.galileo.tv/life/gesetze-neue-regeln-2022-fuer-autofahrer/"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/galileo.png"}
                  className="object-cover w-full h-full"
                  alt="Galileo"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide5">
            <a
              href="https://www.auto-motor-und-sport.de/verkehr/elektroauto-co2-zertifikatverkauf-praemie-geld-treibhausgas-minderungsquote-thg-quote/"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-24">
                <img
                  src={"/PressLogos/automotorsport.png"}
                  className="object-cover w-full h-full"
                  alt="automotorsport"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide6">
            <a
              href="https://teslamag.de/news/extra-einnahme-elektroauto-fahrer-verkauf-thg-quote-kein-ablasshandel-41533"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/TeslamagLogo.png"}
                  className="object-cover w-full h-full"
                  alt="Teslamag"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide7">
            <a
              href="https://efahrer.chip.de/news/jaehrlich-hunderte-euro-geschenkt-nur-weil-sie-ein-e-auto-fahren_106523"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/efahrerLogo.png"}
                  className="object-cover w-full h-full"
                  alt="efahrer"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide8">
            <a
              href="https://www.homeandsmart.de/thg-quote-vergleich"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-32 md:w-44">
                <img
                  src={"/PressLogos/HomeAndSmartLogo.png"}
                  className="object-cover w-full h-full"
                  alt="HomeAndSmartLogo"
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide id="slide8">
            <a
              href="https://www.autobild.de/artikel/thg-quote-einnahmen-praemie-spenden-21304905.html"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-8">
                <img
                  src={"/PressLogos/AutoBild.png"}
                  className="object-cover w-full h-full"
                  alt="AutoBild"
                />
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}
